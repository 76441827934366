import { useEffect, useState } from 'react';

import { Alert, Button, Card, Link, Stack, Typography, useColorScheme } from '@mui/joy';
import { useAuthInfo } from '@propelauth/react';
import CalyxLogo from 'jsx:../../assets/icons/logo.svg';
import CalyxLogoDark from 'jsx:../../assets/icons/logoDark.svg';

import { makeGetRequest, makePostRequest } from '../../services/axios';
import { accountRequest, projectNames } from '../../services/axios/endpoints';

import { RequestForm } from './RequestForm';

export type ErrorType = {
  email: string;
  project: string;
};

export type AccountInfo = {
  email: string;
  projectIds: string[];
};

const AccountRequest = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [loadingAccountRequest, setLoadingAccountRequest] = useState(false);
  const [error, setError] = useState<ErrorType>({
    email: '',
    project: '',
  });
  const [accountInfo, setAccountInfo] = useState<AccountInfo>({
    email: '',
    projectIds: [],
  });
  const [projectNamesData, setProjectNames] = useState<{ id: string; name: string }[]>([]);
  const [errorText, setErrorText] = useState<string[]>([]);
  const { loading } = useAuthInfo();
  const { mode } = useColorScheme();
  const handleSubmit = () => {
    const errorCheck = checkError();
    if (!errorCheck.email && !errorCheck.project) {
      setError({ email: '', project: '' });
      handleAccountRequest();
    }
  };

  const handleChange = (key: 'email' | 'projectIds', data?: string[] | string) => {
    setAccountInfo((prev) => ({ ...prev, [key]: data }));
  };

  const handleAccountRequest = () => {
    setLoadingAccountRequest(true);
    setErrorText([]);
    makePostRequest(accountRequest, accountInfo)
      .then((res) => {
        if (res) {
          setIsSuccess(true);
        }
      })
      .catch((error) => {
        setErrorText(error?.response?.data?.message ? [error?.response?.data?.message] : []);
      })
      .finally(() => {
        setLoadingAccountRequest(false);
      });
  };

  const checkError = () => {
    let updatedError = {
      email: '',
      project: '',
    };
    if (accountInfo.email === '') {
      updatedError = { ...updatedError, email: 'Enter your email' };
    }
    if (accountInfo.projectIds.length === 0) {
      updatedError = { ...updatedError, project: 'Select the projects' };
    }
    setError(updatedError);
    return updatedError;
  };

  const fetchProjectNames = () => {
    makeGetRequest(projectNames).then((res) => {
      setProjectNames(res?.data?.projects || []);
    });
  };

  useEffect(() => {
    fetchProjectNames();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} p={2}>
        {mode === 'light' ? (
          <CalyxLogo width={164} height={36} />
        ) : (
          <CalyxLogoDark width={164} height={36} />
        )}
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'auto'}
        m={2}
      >
        <Card
          sx={{
            width: 1,
            maxWidth: '555px',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          {isSuccess ? (
            <>
              <Typography fontSize={24} fontWeight={'lg'}>
                Account Requested
              </Typography>
              <Typography fontSize={18} fontWeight={'md'}>
                Thank you, we’ve captured your request and it is under review. We will share a
                signup link via email (
                <Link href={`mailto:${accountInfo.email}`} target='_blank'>
                  {accountInfo.email}
                </Link>
                ), once the review is done.
              </Typography>
              <Typography fontSize={14} fontWeight={'md'}>
                Note: Please check your spam folder as well
              </Typography>
            </>
          ) : (
            <>
              <Typography fontSize={24} fontWeight={'lg'}>
                Request an account
              </Typography>
              {errorText.length > 0 && (
                <Alert
                  variant='soft'
                  color='danger'
                  endDecorator={
                    <Button
                      size='sm'
                      variant='plain'
                      color='danger'
                      onClick={() => setErrorText([])}
                    >
                      CLOSE
                    </Button>
                  }
                >
                  <Stack justifyContent={'flex-start'}>
                    {errorText.map((errorText) => (
                      <Typography fontSize={'sm'} fontWeight={'md'}>
                        {errorText}
                      </Typography>
                    ))}
                  </Stack>
                </Alert>
              )}
              <Card
                variant='soft'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                <RequestForm error={error} options={projectNamesData} handleChange={handleChange} />
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Button
                    variant='solid'
                    color='primary'
                    size='md'
                    onClick={handleSubmit}
                    sx={{ flex: 1 }}
                    loading={loadingAccountRequest}
                  >
                    Send Request
                  </Button>
                </Stack>
              </Card>
            </>
          )}
        </Card>
      </Stack>
    </>
  );
};

export { AccountRequest };
