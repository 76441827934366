import React, { useEffect } from 'react';

import { Modal, Box, Button, Stack } from '@mui/joy';
import { IoClose as CloseIcon } from 'react-icons/io5';

import { useZoomAndPan } from '../utils/hooks/useZoomAndPan';

interface ImageViewerProps {
  src: string;
  alt: string;
  onClose: () => void;
  open: boolean;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ src, alt, onClose, open, ...rest }) => {
  const { resetZoom } = useZoomAndPan();
  const { style, ...restProps } = rest as any;

  const handleClose = () => {
    resetZoom();
    onClose();
  };

  useEffect(() => {
    if (!open) {
      resetZoom();
    }
  }, [open, resetZoom]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          bgcolor: 'background.paper',
        }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Stack sx={{ width: '70%', height: '70%', justifyContent: 'center' }}>
            <Button
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginBottom: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
              variant='outlined'
              onClick={handleClose}
              startDecorator={<CloseIcon size={20} />}
            >
              Close
            </Button>

            <img
              src={src}
              alt={alt}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                transition: 'transform 0.3s ease-out',
                userSelect: 'none',
                ...style,
              }}
              draggable={false}
              {...restProps}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageViewer;
