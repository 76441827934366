import { atom, selector } from 'recoil';

import { ESR_BADGE } from '../../utils/constants/localStorage';
import { getLocalStorageObject, setLocalStorageObject } from '../../utils/functions/localStorage';

interface ProjectState {
  projectData: any;
  essRatingData: any;
  projectComparisonData: any;
  isLoading: boolean;
  projectCreditingData: any;
  relatedContentArticles: Array<any>;
  esrBadge: Map<string, boolean>;
}

export const defaultProjectState = {
  projectData: null,
  essRatingData: null,
  projectComparisonData: null,
  isLoading: true,
  projectCreditingData: null,
  relatedContentArticles: [],
  esrBadge: getLocalStorageObject(ESR_BADGE) || new Map(),
};

export const projectState = atom<ProjectState>({
  key: 'ProjectState',
  default: {
    ...defaultProjectState,
  },
  effects: [
    ({ onSet }) => {
      onSet((projectState) => {
        setLocalStorageObject(ESR_BADGE, projectState?.esrBadge);
      });
    },
  ],
});

export const projectStateSelector = selector({
  key: 'ProjectStateSelector',
  get: ({ get }) => get(projectState),
  set: ({ set, get }, newValue) => {
    const currentState = get(projectState);

    set(projectState, {
      ...defaultProjectState,
      ...currentState,
      ...newValue,
      esrBadge: currentState.esrBadge, // Ensure `esrBadge` is not reset
    });
  },
});
