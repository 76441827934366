import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { userState } from '../../../stores/user';

export const useFetchUserPermissions = () => {
  const currentUserInfo = useRecoilValue(userState);

  const hasPermissionForReadMore = useMemo(() => {
    return currentUserInfo?.user_permissions?.includes('platform:deep_dive_access');
  }, [currentUserInfo]);

  const hasPermission = useMemo(() => {
    const accessType = currentUserInfo?.project_access_type;
    return accessType !== 'l0_regular' && !accessType?.startsWith('customrole_');
  }, [currentUserInfo]);

  const hasPermissionForWhereToBuy = hasPermission;
  const hasPermissionForRetirementInsights = hasPermission;

  return {
    hasPermissionForReadMore,
    hasPermissionForWhereToBuy,
    hasPermissionForRetirementInsights,
  };
};
