import React from 'react';

import { Grid } from '@mui/joy';

import { track } from '../../../services/analytics';
import { EVENT_BLOG_PAGE, EVNTAPP_blog_detail_click } from '../../../services/analytics/events';

import CardComponent from './Card';
import CardLoader from './CardLoader';
interface List {
  id: string;
  title: string;
  link: string;
  post: string;
  access_level: string;
  date: string;
  author: string;
  visual: string;
  state: string;
  page?: string;
  type?: string;
  tags: { tag_info: { id: string; tag: string } }[];
}
interface props {
  data: List[];
  isLoading: boolean;
  page: string;
  type?: string;
  selectedTag?: string | null;
}
const ListItem = ({ data, isLoading }: props) => {
  const handleBlogDetailTrack = () => {
    track(EVNTAPP_blog_detail_click, EVENT_BLOG_PAGE);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {isLoading ? (
          Array(6)
            .fill('')
            .map((item, index) => {
              return <CardLoader key={index + item} />;
            })
        ) : (
          <>
            {data?.map((item: any, index) => {
              return (
                <Grid xs={12} sm={6} md={4} onClick={handleBlogDetailTrack}>
                  <CardComponent
                    data={{
                      date: item?.published_date,
                      title: item?.title,
                      visual: item?.cover?.url,
                      link: item?.content_detail_alternate_url,
                      slug: item?.url,
                      category: item?.post_category?.url_slug,
                    }}
                    key={index}
                  />
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};
export default ListItem;
