import { Stack, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../stores/project';

import ListItem from './components/ListItem';

export const RelatedContent = () => {
  const { relatedContentArticles, isLoading } = useRecoilValue(projectState);

  return (
    <Stack gap={3} alignContent={'flex-start'} sx={{ mx: 'auto', maxWidth: 'lg', pb: 10 }}>
      <Typography level='h2' fontWeight={'xl'}>
        Related Content
      </Typography>
      <ListItem data={relatedContentArticles} isLoading={isLoading} page={''} selectedTag={null} />
    </Stack>
  );
};
