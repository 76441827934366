import { Article } from '../../types/strapi';

export interface StrapiProps {
  endpoint: string;
  query?: Record<string, string>;
  wrappedByKey?: string;
  wrappedByList?: boolean;
}

export async function fetchApi<T>({
  endpoint,
  query,
  wrappedByKey,
  wrappedByList,
}: StrapiProps): Promise<T | undefined> {
  try {
    if (endpoint.startsWith('/')) {
      endpoint = endpoint.slice(1);
    }

    const url = new URL(`${process.env.STRAPI_URL}/api/${endpoint}`);

    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });
    }

    const res = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    let data = await res.json();

    if (wrappedByKey) {
      data = data[wrappedByKey];
    }

    if (wrappedByList) {
      data = data[0];
    }

    return data as T;
  } catch (error) {
    console.error('Error fetching API:', error);
  }
}

export interface GetAllArticlesByCategoryProps {
  category: string | Array<string> | null;
  subscriberOnly?: boolean;
}

export const getAllArticlesByCategory = async (config: GetAllArticlesByCategoryProps) => {
  const { category, subscriberOnly } = config;
  const articles = await fetchApi<Article[]>({
    endpoint: 'articles',
    query: {
      'populate[0]': 'cover',
      'populate[1]': 'post_category',
      'sort[0]': 'published_date:desc',
      'pagination[pageSize]': '500',
      'filters[visibility][$ne]': 'public_only',
      status: process.env.IS_PRODUCTION_APP === 'true' ? 'published' : 'draft',
      ...(category !== null && !subscriberOnly
        ? Array.isArray(category)
          ? category
              .map((cat, i) => {
                return { [`filters[$or][${i}][post_category][url_slug][$eqi]`]: cat };
              })
              .reduce((acc, obj) => {
                return { ...acc, ...obj };
              }, {})
          : { 'filters[post_category][url_slug][$eqi]': category }
        : {}),
      ...(subscriberOnly ? { 'filters[visibility][$eq]': 'subscriber_only' } : {}),
    },
    wrappedByKey: 'data',
  });
  return articles || [];
};
