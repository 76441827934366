import { Button, Card, Skeleton, Stack, Typography } from '@mui/joy';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { dateFormat } from '../../../utils/functions/date';

interface Props {
  data: any;
  isLoading?: boolean;
}

export default function ResearchUpdateCard({ data, isLoading = false }: Props) {
  return isLoading ? (
    <Card variant='soft' sx={{ position: 'relative', pb: 10, height: '100% ' }}>
      <Stack direction={'column'} gap={1}>
        <Skeleton variant='rectangular' width={'40%'} height={'24px'} animation='wave' />
        {Array(5)
          .fill('')
          .map((_, i) => {
            return (
              <Skeleton
                variant='rectangular'
                width={'100%'}
                height={'14px'}
                animation='wave'
                key={i}
              />
            );
          })}
        <Skeleton variant='rectangular' width={'60%'} height={'14px'} animation='wave' />
      </Stack>
      <Button
        variant='plain'
        size='sm'
        endDecorator={<FaChevronRight style={{ marginTop: '2px' }} />}
        sx={{ width: 'max-content', position: 'absolute', bottom: 24, left: 12 }}
      >
        View More
      </Button>
    </Card>
  ) : (
    <Link
      to={isLoading ? '' : `/research-hub/research_update/${data?.date}`}
      style={{ textDecoration: 'none', color: 'unset' }}
    >
      <Card variant='soft' sx={{ position: 'relative', pb: 10, height: '100% ' }}>
        <Stack direction={'column'} gap={0.5}>
          <Typography level='title-lg'>{dateFormat(data?.date).monthddyyyy}</Typography>
          <Typography
            level='title-sm'
            fontWeight={'sm'}
            sx={(theme) => {
              return { color: theme.palette.text.secondary };
            }}
          >
            {data?.preview}
          </Typography>
        </Stack>
        <Button
          variant='plain'
          size='sm'
          endDecorator={<FaChevronRight style={{ marginTop: '2px' }} />}
          sx={{ width: 'max-content', position: 'absolute', bottom: 24, left: 12 }}
        >
          View More
        </Button>
      </Card>
    </Link>
  );
}
