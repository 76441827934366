import { useQuery } from '@tanstack/react-query';

import { getAllArticlesByCategory, GetAllArticlesByCategoryProps } from '../strapi';

export default function useFetchAllArticlesByCategory(props: GetAllArticlesByCategoryProps) {
  const query = useQuery({
    queryKey: ['insights-hub', props.category],
    queryFn: async () => {
      const response = await getAllArticlesByCategory(props);
      return response;
    },
  });

  return query;
}
