import axios from 'axios';

type Options = {
  q: string;
  query_by?: string[];
  page?: number;
  per_page?: number;
};
export async function makeIndexedInsightsHubSearchRequest({
  q,
  page,
  per_page,
  query_by,
}: Options) {
  const endpoint = process.env.IS_PRODUCTION_APP
    ? '/collections/articles/documents/search/'
    : '/collections/articles-draft/documents/search/';

  const payload: Record<string, string> = {
    q,
    query_by: query_by?.join(',') ?? 'title,content,summary,preview',
    per_page: `${per_page ?? '20'}`,
    page: `${page ?? '1'}`,
    sort_by: '_text_match:desc,published_date:desc',
    query_by_weights: '4,1,1,1',
    prioritize_exact_match: 'true',
  };

  const queryString = new URLSearchParams(payload).toString();

  const res = await axios.get(`${process.env.TYPESENSE_URL}${endpoint}?${queryString}`, {
    headers: {
      'X-TYPESENSE-API-KEY': process.env.TYPESENSE_API_KEY,
    },
  });

  return res.data?.hits;
}
