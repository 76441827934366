import { useQuery } from '@tanstack/react-query';

import { fetchApi } from '../strapi';

interface Props {
  projectType: string;
}

export default function useFetchStrapiArticlesByProjectType({ projectType }: Props) {
  const query = useQuery({
    queryKey: ['insights-hub', projectType],
    queryFn: async () => {
      const response = await fetchApi({
        endpoint: 'articles',
        query: {
          'filters[project_types][name][$eq]': projectType,
          'populate[0]': 'cover',
          'populate[1]': 'author',
          'populate[2]': 'next_up_articles.cover',
          'populate[3]': 'post_category',
          status: process.env.IS_PRODUCTION_APP === 'true' ? 'published' : 'draft',
        },
        wrappedByKey: 'data',
      });
      return response;
    },
  });

  return query;
}
