import React from 'react';

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  styled,
  useTheme,
} from '@mui/joy';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import { ErrorType } from './AccountRequest';

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: 'normal',
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: theme.vars.fontWeight.md,
}));

const RequestForm: React.FC<{
  handleChange: (key: 'email' | 'projectIds', data: string | string[]) => void;
  error: ErrorType;
  options: { id: string; name: string }[];
}> = ({ handleChange, error, options }) => {
  const theme = useTheme();
  return (
    <>
      <FormControl
        error={!!error.email}
        onChange={(e) => handleChange('email', (e.target as HTMLInputElement).value)}
      >
        <StyledFormLabel>Email address</StyledFormLabel>
        <Input type='email' name='email' />
        {error.email && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.email}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
      <FormControl error={!!error.project}>
        <StyledFormLabel>Project name</StyledFormLabel>
        <Autocomplete
          multiple
          name='projectIds'
          getOptionLabel={(option: { name: string; id: string }) => `${option.id} ${option.name}`}
          options={options}
          onChange={(_, value) =>
            handleChange(
              'projectIds',
              value.map((p) => p.id)
            )
          }
        />
        {error.project && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.project}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
    </>
  );
};

export { RequestForm };
