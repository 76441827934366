import { Box, Skeleton, Stack, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';

import openPadlockPurple from '../assets/icons/openPadlockPurple.svg';
import { pinnedPostObj } from '../pages/insightsHub/constants';
import { Article } from '../types/strapi';
import { dateFormat } from '../utils/functions/date';
import useIsMobile from '../utils/hooks/useIsMobile';

import Image from './Image';

interface Props {
  article: Article;
  isLoading?: boolean;
  isPinnedPost?: boolean;
}

export default function ArticleThumb({ article, isLoading = false, isPinnedPost = false }: Props) {
  const isMobile = useIsMobile();
  return (
    <Link
      to={
        isLoading
          ? ''
          : isPinnedPost
            ? pinnedPostObj.url
            : article?.content_detail_alternate_url ??
              `/research-hub/${article.post_category.url_slug}/${article?.url}`
      }
      target={article?.content_detail_alternate_url ? '_blank' : '_self'}
      style={{ textDecoration: 'none' }}
    >
      <Stack
        overflow={'hidden'}
        direction={'row'}
        borderRadius={'md'}
        width={'100%'}
        spacing={1}
        height={{ lg: '118px' }}
        alignItems={{ xs: 'center', lg: 'start' }}
      >
        <Box
          minWidth={{ xs: '116px', lg: '200px' }}
          maxWidth={{ xs: '116px', lg: '200px' }}
          width={'100%'}
          position={'relative'}
        >
          {article?.visibility === 'subscriber_only' ? (
            <Box position={'absolute'} right={12} top={4}>
              <Image src={openPadlockPurple} style={{ width: '24px', aspectRatio: '1/1' }} />
            </Box>
          ) : null}
          <Box
            sx={(theme) => {
              return {
                borderBottomLeftRadius: isMobile ? theme.radius.xs : theme.radius.md,
                borderTopLeftRadius: isMobile ? theme.radius.xs : theme.radius.md,
              };
            }}
            overflow={'hidden'}
            height={{ xs: '64px', lg: '112px' }}
          >
            {isLoading ? (
              <Skeleton
                animation='wave'
                variant='rectangular'
                width={'100%'}
                height={{ xs: '64px', lg: '200px' }}
              />
            ) : (
              <Image
                src={article?.cover?.url}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            )}
          </Box>
        </Box>
        <Box width={'100%'}>
          {isLoading ? (
            <Stack direction={'column'} spacing={1}>
              <Skeleton
                animation='wave'
                width={'100%'}
                height={{ lg: '24px', xs: '14px' }}
                variant='rectangular'
              />
              <Skeleton
                animation='wave'
                width={'100%'}
                height={{ lg: '24px', xs: '14px' }}
                variant='rectangular'
              />
              <Skeleton
                animation='wave'
                width={'40%'}
                height={{ lg: '24px', xs: '14px' }}
                variant='rectangular'
              />
            </Stack>
          ) : (
            <Typography level={isMobile ? 'title-sm' : 'title-lg'}>
              <p className='truncate-multiline'>{article?.title}</p>
            </Typography>
          )}
          {isLoading ? (
            <Skeleton
              animation='wave'
              width={'80%'}
              height={{ lg: '16px', xs: '12px' }}
              variant='rectangular'
              sx={{ mt: 1 }}
            />
          ) : (
            <Typography
              mt={{ lg: 1, xs: 0.5 }}
              level={isMobile ? 'body-xs' : 'title-sm'}
              sx={(theme) => {
                return { color: theme.palette.text.secondary };
              }}
            >
              {dateFormat(article?.published_date).monthddyyyy}
            </Typography>
          )}
        </Box>
      </Stack>
    </Link>
  );
}
