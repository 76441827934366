import { styled } from '@mui/joy';

export default styled('div')(({ theme }) => ({
  color: `${theme.vars.palette.text.primary} !important`,
  fontFamily: theme?.fontFamily?.body,
  fontSize: theme.vars.fontSize.md,
  fontStyle: 'normal',
  fontWeight: theme.vars.fontWeight.sm,
  lineHeight: theme.vars.lineHeight.md,
  p: {
    paddingBottom: `${theme.spacing(3)}`,
  },
  span: {
    color: `${theme.vars.palette.text.primary} !important`,
    background: 'none !important',
    lineHeight: '175% !important',
  },
  ol: {
    listStyleType: 'decimal',
    paddingLeft: theme.spacing(4),
    marginTop: `${theme.spacing(1.5)}`,
    li: {
      margin: `${theme.spacing(1)} 0px`,
    },
    paddingBottom: `${theme.spacing(3)}`,
  },
  ul: {
    paddingLeft: '32px',
    marginTop: `${theme.spacing(1.5)}`,
    li: {
      listStyle: 'disc',
      margin: `${theme.spacing(1)} 0px`,
    },
    paddingBottom: `${theme.spacing(3)}`,
  },
  'p + ul, p + ol': {
    marginTop: '-24px',
  },
  i: {
    fontStyle: 'italic !important',
  },
  'h1, h1 span': {
    fontSize: '26px',
    fontWeight: `600 !important`,
    lineHeight: '32px',
    margin: '0.5rem 0px',
    letterSpacing: '-1.5%',
  },
  'h2, h2 span': {
    fontSize: '22px',
    fontWeight: `600 !important`,
    lineHeight: '32px',
    letterSpacing: '-1.5%',
    margin: '0.5rem 0px',
  },
  'h3, h3 span': {
    fontWeight: `600 !important`,
    fontSize: '20px',
    lineHeight: '28px',
  },
  'h4, h4 span': {
    fontWeight: `600 !important`,
    fontSize: '18px',
    lineHeight: '28px',
  },
  'h5, h5 span': {
    fontSize: '1.25rem',
    fontWeight: `500 !important`,
    lineHeight: '1.5',
    margin: '0.5rem 0px',
  },
  'li > a': {
    color: theme.vars.palette['hyperlink']['text'],
    fontWeight: theme.vars.fontWeight.sm,
  },
  '.RichText3-paragraph': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.xs,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      textDecoration: 'underline',
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: '1.5rem',
    },
    '& > img': {
      width: '100%',
      objectFit: 'contain',
      maxHeight: '60vh',
      maxWidth: '100%',
    },
  },
  'figure:has(img)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100% !important',
  },
  img: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '60vh',
    margin: `${theme.spacing(1)} auto`,
    maxWidth: '100%',
  },
  '& > h4': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.xl3,
    fontWeight: theme.vars.fontWeight.md,
    fontStyle: 'normal',
    lineHeight: theme.vars.lineHeight.md,
  },
  '& > h5': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.xl2,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.md,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
    '& > img': {
      width: '100%',
      objectFit: 'contain',
      maxHeight: '60vh',
      maxWidth: '100%',
    },
  },
  '& > p': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.xs,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
    wordWrap: 'break-word',
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      textDecoration: 'underline',
      fontFamily: theme?.fontFamily?.body,
      fontSize: theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: '1.5rem',
    },
    '& > img': {
      width: '100%',
      objectFit: 'contain',
      height: 'min-content',
      maxWidth: '100%',
    },
    '& > i': {
      fontStyle: 'italic',
    },
    '& > em': {
      fontStyle: 'italic',
    },
  },
  '& > a': {
    color: theme.vars.palette['hyperlink']['text'],
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.sm,
    lineHeight: theme.vars.lineHeight.md,
    margin: `${theme.spacing(1)} 0px`,
  },
  a: {
    textDecoration: 'none !important',
    color: `${theme.vars.palette['hyperlink']['text']} !important`,
  },
  'a span': {
    textDecoration: 'none !important',
    color: `${theme.vars.palette['hyperlink']['text']} !important`,
  },
  'a:hover': {
    textDecoration: 'underline !important',
  },
  'figure:has(table)': {
    width: '100%',
    overflow: 'auto',
    display: 'block',
  },
  figure: {
    paddingBottom: theme.spacing(3),
  },
  table: {
    margin: 'auto',
    width: '100%',
    tr: {
      th: {
        padding: theme.spacing(2),
        border: '1px solid #4D4D4D',
        verticalAlign: 'middle',
      },
      td: {
        padding: theme.spacing(2),
        border: '1px solid #4D4D4D !important',
        verticalAlign: 'middle',
        wordWrap: 'break-word',
        'p:last-child': {
          paddingBottom: '0px !important',
        },
        'ul:last-child, ol:last-child': {
          paddingBottom: '0px !important',
          marginTop: '0px !important',
        },
      },
      'span, p': {
        padding: '0px !important',
        paddingBottom: '24px !important',
      },
    },
    'tr:first-child th': {
      fontWeight: 700,
    },
  },
  '& > div': {
    '& > table': {
      ' & > tr': {
        '& > th': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
        '& > td': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
      },
    },
  },
  sup: {
    position: 'relative',
    top: '-8px',
    fontSize: theme.vars.fontSize.sm,
  },
  sub: {
    position: 'relative',
    bottom: '-8px',
    fontSize: theme.vars.fontSize.sm,
  },
  iframe: {
    margin: '16px auto',
    width: '100%',
  },
}));
