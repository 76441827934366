import { useEffect, useMemo } from 'react';

import { Box, Card, Stack, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';

import ArticleThumb from '../../components/ArticleThumb';
import { track } from '../../services/analytics';
import {
  EVENT_INSIGHTS_HUB_PAGE,
  PGV_research_hub_category_detail,
} from '../../services/analytics/events';
import useFetchAllArticlesByCategory from '../../services/strapi/useFetchAllArticlesByCategory';
import useFetchAllResearchUpdates from '../../services/strapi/useFetchAllResearchUpdates';
import { Article } from '../../types/strapi';
import FeaturedArticle from '../insightsHub/components/FeaturedArticle';
import {
  insightsHubCategoriesConfig,
  PINNED_BLOG_POST_DISPLAY_WHITELIST,
  pinnedPostObj,
} from '../insightsHub/constants';

import ResearchUpdateCard from './components/ResearchUpdateCard';

export const InsightsHubCategory = () => {
  const { category } = useParams();
  const categoryConfig = useMemo(
    () => insightsHubCategoriesConfig.find(({ key }) => category === key),
    [category]
  );
  const {
    data = [],
    refetch,
    isLoading,
  } = useFetchAllArticlesByCategory({
    category: categoryConfig?.filterCombination
      ? categoryConfig?.filterCombination
      : categoryConfig?.key === 'all'
        ? null
        : `${categoryConfig?.key}`,
  });

  const { data: featuredArticleData, isLoading: isFeatureLoading } = useFetchAllArticlesByCategory({
    category: null,
  });

  const featuredArticleTwo = useMemo(() => {
    return featuredArticleData
      ?.filter(({ is_featured }) => is_featured === 'featured_two')
      ?.sort(
        (a, b) => new Date(b?.published_date).getTime() - new Date(a?.published_date).getTime()
      )?.[0];
  }, [featuredArticleData]);

  useEffect(() => {
    refetch();
  }, [category]);

  const displayPinnedPost = useMemo(
    () => PINNED_BLOG_POST_DISPLAY_WHITELIST.includes(`${category}`),
    [category]
  );

  const { data: researchUpdates, isLoading: isResearchUpdatesLoading } =
    useFetchAllResearchUpdates();

  useEffect(() => {
    if (data) {
      track(PGV_research_hub_category_detail, EVENT_INSIGHTS_HUB_PAGE, {
        category,
      });
    }
  }, [data]);

  return category === 'research-updates' ? (
    <Card>
      <Box
        display={'grid'}
        gap={3}
        gridTemplateColumns={{ xs: '1fr', md: 'repeat(2,1fr)', lg: 'repeat(3,1fr)' }}
      >
        {isResearchUpdatesLoading
          ? Array(12)
              .fill('')
              .map((_, i) => {
                return <ResearchUpdateCard data={{}} isLoading={true} key={i} />;
              })
          : (researchUpdates as any[])?.map((update) => {
              return <ResearchUpdateCard data={update} key={update?.id} />;
            })}
      </Box>
    </Card>
  ) : (
    <Stack direction={'column'} spacing={3}>
      <Card>
        {!isLoading && data?.length < 1 ? (
          <Box
            height={'200px'}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography>No articles found</Typography>
          </Box>
        ) : (
          <Box display={'grid'} gap={3} gridTemplateColumns={{ xs: '1fr', lg: 'repeat(3,1fr)' }}>
            {isLoading ? (
              Array(6)
                .fill('')
                .map((_, i) => {
                  return <ArticleThumb article={{} as Article} key={i} isLoading />;
                })
            ) : (
              <>
                {displayPinnedPost ? <ArticleThumb article={pinnedPostObj} isPinnedPost /> : null}
                {data?.slice(0, displayPinnedPost ? 5 : 6)?.map((article) => {
                  return <ArticleThumb article={article} key={article?.id} />;
                })}
              </>
            )}
          </Box>
        )}
      </Card>
      <FeaturedArticle
        data={featuredArticleTwo as Article}
        showVertical={false}
        isLoading={isFeatureLoading}
      />
      {isLoading ? (
        Array(6)
          .fill('')
          .map((_, i) => {
            return <ArticleThumb article={{} as Article} key={i} isLoading />;
          })
      ) : data?.slice(6)?.length > 0 ? (
        <Card>
          <Box display={'grid'} gap={3} gridTemplateColumns={{ xs: '1fr', lg: 'repeat(3,1fr)' }}>
            {data?.slice(displayPinnedPost ? 5 : 6)?.map((article) => {
              return <ArticleThumb article={article} key={article?.id} />;
            })}
          </Box>
        </Card>
      ) : null}
    </Stack>
  );
};
