export const programsList = [
  {
    name: 'ACR',
    link: 'https://acrcarbon.org/',
    summary:
      'ACR meets all of the most important screening criteria. However, the program’s transparency regarding credit retirements and available documentation on the registry could be improved.',
    integrity: 'gold',
    completionDate: 'November 8, 2024',
  },
  {
    name: 'Architecture for REDD+ Transactions (ART)',
    link: 'https://www.artredd.org/',
    summary:
      'ART meets nearly all of the 31 criteria in our screening, including all of the most important screening criteria. However, as with all carbon crediting programs that we have assessed to date, it generates revenue in a way that could generate a financial conflict of interest.',
    integrity: 'gold',
    completionDate: 'July 30, 2024',
  },
  {
    name: 'Clean Development Mechanism (CDM)',
    link: 'https://cdm.unfccc.int/',
    summary:
      'CDM meets all of the most important screening criteria. However, the program’s transparency regarding credit retirements and procedures to prevent double issuance could be improved.',
    integrity: 'gold',
    completionDate: 'December 3, 2024',
  },
  {
    name: 'Ecosystem Restoration Standard (ERS)',
    link: 'https://www.ers.org/',
    summary:
      'ERS meets nearly all of the 31 criteria in our screening, including all of the most important screening criteria. However, available documentation on the registry could be improved.',
    integrity: 'gold',
    completionDate: 'January 14, 2025',
  },
  {
    name: 'Open Carbon Protocol (OCP)',
    link: 'https://www.ocp.earth/',
    summary:
      'OCP meets nearly all of the 31 criteria in our screening, including all of the most important screening criteria. However, as with all carbon crediting programs that we have assessed to date, it generates revenue in a way that could generate a financial conflict of interest.',
    integrity: 'gold',
    completionDate: 'November 13, 2024',
  },
  {
    name: 'puro.earth',
    link: 'https://puro.earth/',
    summary:
      'Puro Earth meets all of the most important screening criteria. However, the program’s transparency regarding projects’ status and available documentation on the registry could be improved.',
    integrity: 'gold',
    completionDate: 'October 10, 2024',
  },
  {
    name: 'Climate Action Reserve (CAR)',
    link: 'https://www.climateactionreserve.org/',
    summary:
      'CAR meets almost all of the most important screening criteria. One important criterion that the program falls short of is the lack of publicly available conflict of interest (COI) provisions. Additionally, the program’s transparency regarding credit retirements and available documentation on the registry could be improved.',
    integrity: 'silver',
    completionDate: 'May 21, 2024',
  },
  {
    name: 'Verified Carbon Standard (VCS)',
    link: 'https://verra.org/programs/verified-carbon-standard/',
    summary:
      'VCS meets almost all of the most important screening criteria. One important shortcoming of the program is that it does not require the entity that retired a unit to be made publicly available on its registry. Additionally, the program’s transparency regarding credit retirements (retirement beneficiary and purpose of retirement) and project stakeholder comments could be improved.',
    integrity: 'silver',
    completionDate: 'September 18, 2024',
  },
  {
    name: 'Gold Standard for the Global Goals (GS)',
    link: 'https://globalgoals.goldstandard.org/',
    summary:
      'Gold Standard meets almost all of the most important screening criteria. However, the registry often does not publicly display the entity that retired a unit. Additionally, the program’s transparency regarding credit retirements (retirement beneficiary and purpose of retirement) could be improved. While the program’s documents appear to require this information be made publicly available, in practice, we find that this information is not always provided. Furthermore, the program engages in selling the credits that it issues for a commission, creating a unique conflict.',
    integrity: 'silver',
    completionDate: 'January 30, 2025',
  },
  {
    name: 'BCarbon',
    link: 'https://bcarbon.org/',
    summary:
      'BCarbon does not meet the minimum screening criteria. The program has a general lack of transparency, and BCarbon’s regulatory documents and core normative references are not publicly available. As such, the program fails to meet most of the six criteria strictly required for programs to pass our screening.',
    integrity: 'fail',
    completionDate: 'November 15, 2024',
  },
  {
    name: 'REDD.Plus',
    link: 'https://www.redd.plus/',
    summary:
      'REDD.Plus does not meet the minimum screening criteria. The program has a general lack of transparency, and REDD.Plus’s regulatory documents, core normative references, and registry are not publicly available. As such, the program fails to meet most of the six criteria strictly required for programs to pass our screening.',
    integrity: 'fail',
    completionDate: 'November 19, 2024',
  },
];
