import { Box, Button, ButtonGroup } from '@mui/joy';
import { FaArrowLeft } from 'react-icons/fa';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { insightsHubCategoriesConfig } from '../pages/insightsHub/constants';

import InsightsHubSearch from './InsightsHubSearch';

export default function InsightsHubLayout() {
  const { category, slug } = useParams();
  const navigate = useNavigate();

  const handleNavItem = (key) => {
    navigate(`/research-hub/${key}`);
  };

  const handleReturn = () => {
    navigate(-1);
  };

  return (
    <Box paddingBottom={12} maxWidth={'1440px'} margin={'auto'} paddingTop={3} paddingX={2}>
      {!!slug ? (
        <Box maxWidth={'1117px'} margin={'auto'}>
          <Button
            variant='outlined'
            size='sm'
            startDecorator={<FaArrowLeft />}
            sx={{ marginBottom: 3 }}
            onClick={handleReturn}
          >
            Return
          </Button>
        </Box>
      ) : (
        <Box
          alignItems={'center'}
          gap={{ xs: 2, lg: 4 }}
          width={'100%'}
          marginBottom={3}
          justifyContent={'space-between'}
          gridTemplateColumns={{ xs: '1fr', lg: '5fr 7fr' }}
          display={'grid'}
        >
          <Box>
            <InsightsHubSearch />
          </Box>
          <Box maxWidth={'100vw'} width={{ xs: '100%', lg: '810px' }} overflow={'auto'}>
            <ButtonGroup color='primary' sx={{ width: 'max-content' }}>
              {insightsHubCategoriesConfig
                .sort((a, b) => a?.orderIndex - b?.orderIndex)
                .map(({ key, label }) => {
                  const isSelected = category === key;
                  return (
                    <Button
                      variant={isSelected ? 'solid' : 'outlined'}
                      sx={{ width: 'max-content' }}
                      onClick={() => handleNavItem(key)}
                      key={key}
                    >
                      {label}
                    </Button>
                  );
                })}
            </ButtonGroup>
          </Box>
        </Box>
      )}
      <Outlet />
    </Box>
  );
}
