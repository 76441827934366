import { useQuery } from '@tanstack/react-query';

import { fetchApi } from '../strapi';

interface Props {
  slug: string;
}

export default function useFetchStrapiArticle({ slug }: Props) {
  const query = useQuery({
    queryKey: ['insights-hub', slug],
    queryFn: async () => {
      const response = await fetchApi({
        endpoint: 'articles',
        query: {
          'filters[url][$eqi]': slug,
          'populate[0]': 'cover',
          'populate[1]': 'author',
          'populate[2]': 'next_up_articles.cover',
          'populate[3]': 'resource_post_file_for_platform',
          'populate[4]': 'post_category',
          status: process.env.IS_PRODUCTION_APP === 'true' ? 'published' : 'draft',
        },
        wrappedByKey: 'data',
      });
      return response;
    },
    select: (data) => data?.[0],
  });

  return query;
}
