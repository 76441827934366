import { Box, Card, Skeleton, Stack, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';

import openPadlockPurple from '../assets/icons/openPadlockPurple.svg';
import { Article } from '../types/strapi';
import { dateFormat } from '../utils/functions/date';

import Image from './Image';

interface Props {
  article: Article;
  isLoading?: boolean;
}

export default function ArticleThumbLarge({ article, isLoading }: Props) {
  return (
    <Link
      to={
        isLoading
          ? ''
          : article?.content_detail_alternate_url ??
            `/research-hub/${article.post_category.url_slug}/${article?.url}`
      }
      target={article?.content_detail_alternate_url ? '_blank' : '_self'}
      style={{ textDecoration: 'none' }}
    >
      <Card
        sx={(theme) => {
          return {
            padding: 0,
            borderRadius: theme.radius.md,
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
          };
        }}
      >
        {article?.visibility === 'subscriber_only' ? (
          <Box position={'absolute'} right={12} top={4}>
            <Image src={openPadlockPurple} style={{ width: '32px', aspectRatio: '1/1' }} />
          </Box>
        ) : null}
        {isLoading ? (
          <Skeleton variant='rectangular' width={'100%'} height={'150px'} animation='wave' />
        ) : (
          <Image
            src={article?.cover?.url}
            style={{ width: '100%', height: '150px', objectFit: 'cover' }}
          />
        )}
        <Stack direction={'column'} gap={1} padding={1}>
          {isLoading ? (
            <Skeleton
              animation='wave'
              width={'100%'}
              height={{ lg: '24px', xs: '14px' }}
              variant='rectangular'
            />
          ) : (
            <Typography level='title-lg'>{article?.title}</Typography>
          )}
          {isLoading ? (
            <Skeleton
              animation='wave'
              width={'40%'}
              height={{ lg: '16px', xs: '12px' }}
              variant='rectangular'
              sx={{ mt: 1 }}
            />
          ) : (
            <Typography level='title-sm' color='neutral'>
              {dateFormat(article?.published_date).monthddyyyy}
            </Typography>
          )}
        </Stack>
      </Card>
    </Link>
  );
}
