import { useQuery } from '@tanstack/react-query';

import { fetchApi } from '../strapi';

interface Props {
  date: string;
}

export default function useFetchResearchUpdate({ date }: Props) {
  const query = useQuery({
    queryKey: ['research-update', date],
    queryFn: async () => {
      const response = await fetchApi({
        endpoint: 'research-updates',
        query: {
          'filters[date][$eqi]': date,
          'populate[0]': 'author',
          'populate[1]': 'next_up_updates',
          status: process.env.IS_PRODUCTION_APP === 'true' ? 'published' : 'draft',
        },
        wrappedByKey: 'data',
      });
      return response;
    },
    select: (data) => data?.[0],
  });

  return query;
}
