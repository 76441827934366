import { Box, Card, Stack, Typography } from '@mui/joy';

import ArticleThumbLarge from '../../../components/ArticleThumbLarge';
import { Article } from '../../../types/strapi';

interface Props {
  isLoading: boolean;
  articles: Array<Article>;
}

export default function NextUpArticles({ isLoading, articles }: Props) {
  return (
    <Card>
      <Stack direction={'column'} gap={3} width={'100%'}>
        <Typography level='h3' fontWeight={'xl'}>
          Next Up
        </Typography>
        <Box display={'grid'} gap={3} gridTemplateColumns={{ xs: '1fr', lg: 'repeat(3,1fr)' }}>
          {isLoading
            ? Array(3)
                .fill('')
                .map((_, i) => {
                  return <ArticleThumbLarge article={{} as Article} key={i} isLoading />;
                })
            : articles?.map((article) => {
                return <ArticleThumbLarge article={article} key={article?.id} />;
              })}
        </Box>
      </Stack>
    </Card>
  );
}
