import React from 'react';

import { CircularProgress, Link, Stack, Typography } from '@mui/joy';

import { getIntegrityColorCode } from '../../utils/constants/colorCode';

import { programsList } from './constants';
import { StyledTable } from './styles';

const ProgramTable: React.FC = () => {
  return (
    <StyledTable borderAxis='both'>
      <thead>
        <tr>
          <th style={{ width: '222px' }}>Program</th>
          <th style={{ width: 'auto' }}>Summary</th>
          <th style={{ width: '170px', textAlign: 'center' }}>Screening result</th>
        </tr>
      </thead>
      <tbody>
        {programsList.map(({ name, link, summary, integrity, completionDate }) => (
          <tr>
            <td>
              <Link target='_blank' href={link}>
                {name}
              </Link>
            </td>
            <td>
              <Typography level='body-md' color='primary'>
                {summary} Screening completed on {completionDate}.
              </Typography>
            </td>
            <td>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <CircularProgress
                  value={100}
                  determinate
                  component={'div'}
                  size='lg'
                  sx={{
                    '--CircularProgress-size': '74px',
                    '--CircularProgress-progressColor': (theme) =>
                      getIntegrityColorCode(theme)[integrity],
                  }}
                >
                  <Typography
                    fontSize={'xs'}
                    fontWeight={'xl'}
                    textAlign={'center'}
                    sx={{ width: '80px', textTransform: 'uppercase', wordSpacing: '2px' }}
                    color='primary'
                  >
                    {integrity}
                  </Typography>
                </CircularProgress>
              </Stack>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export { ProgramTable };
