import { styled } from '@mui/joy';

const Wrapper = styled('div')(({}) => ({
  u: {
    textDecoration: 'none !important',
  },
  '.truncate-multiline': {
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  '::-webkit-scrollbar': {
    width: '5px !important',
    height: '5px !important',
    borderRadius: '10px !important',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent !important',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#C3CAD7 !important',
    borderRadius: '10px !important',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555 !important',
  },
}));

export const GlobalCustomCSSProvider = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};
