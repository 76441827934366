import { useEffect } from 'react';

import { Box } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import Paywall from '../../components/Paywall';
import { makeAuthenticatedGetRequest } from '../../services/axios';
import {
  esrSummary,
  projectDetails,
  projectGhgSdg,
  projectIssuaranceData,
} from '../../services/axios/endpoints';
import useFetchStrapiArticlesByProjectType from '../../services/strapi/useFetchStrapiArticlesByProjectType';
import { projectState, projectStateSelector } from '../../stores/project';
import { updateMap } from '../../utils/functions/map';

import ProjectHeader from './components/ProjectHeader';
import ProjectSidebar from './components/ProjectSidebar';
import { getIssuanceSizeCategory, getProjectDetail } from './constants';

export const Project = () => {
  const [params] = useSearchParams();
  const { esrBadge } = useRecoilValue(projectState);
  const setProjectState = useSetRecoilState(projectState);
  const resetProjectState = useResetRecoilState(projectStateSelector);
  const ghg_standard_code = params.get('standard');
  const crediting_period_id = params.get('cp_no');
  const project_id = params.get('id') as string;
  const { data, isFetched, isRefetching } = useQuery({
    queryKey: [`project-detail`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectDetails, {
        params: { project_id, crediting_period_id, ghg_standard_code },
      }),
    select: (data) => data?.data?.projects,
  });

  const {
    data: relatedContentArticles,
    refetch,
    isLoading: isRelatedContentArticlesLoading,
  } = useFetchStrapiArticlesByProjectType({
    projectType: data?.project_types?.[0]?.project_type?.name,
  });
  useEffect(() => {
    refetch();
  }, [data]);

  const {
    data: essRatingData,
    isFetched: isFetchedEssRating,
    isRefetching: isEssLoading,
  } = useQuery({
    queryKey: ['esr_summary', data?.project_crediting_periods?.[0]?.id],
    queryFn: async () => {
      return await makeAuthenticatedGetRequest(esrSummary, {
        params: { crediting_period_id: data?.project_crediting_periods?.[0]?.id },
      });
    },
    select: (data) => {
      return data?.data?.data;
    },
    enabled: !!data?.project_crediting_periods?.[0]?.id,
  });

  const {
    data: projectComparisonData,
    isLoading: isProjectCompLoading,
    isRefetching: isProjectCompRefetch,
  } = useQuery({
    queryKey: [`project-sdg-ghg-all_project`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectGhgSdg, {
        params: {
          project_id,
          crediting_period_id,
          ghg_standard_code,
          filter: 'all_project',
        },
      }),
    select: (data) => data?.data.graph,
  });

  const {
    data: projectCreditingData,
    isFetched: isProjectCreditingDataFetched,
    isRefetching: isProjectCreditingLoading,
  } = useQuery({
    queryKey: [`project-crediting`],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectIssuaranceData, {
        params: { project_id, ghg_standard_code },
      }),
    select: (data) => data?.data.issuanceData,
  });

  useEffect(() => {
    if (
      isRefetching ||
      isRelatedContentArticlesLoading ||
      isEssLoading ||
      isProjectCreditingLoading
    ) {
      setProjectState((value) => ({
        ...value,
        isLoading: true,
      }));
    } else if (isFetched && isFetchedEssRating && isProjectCreditingDataFetched) {
      const transformedProjectData = getProjectDetail(data);
      setProjectState((value) => ({
        ...value,
        projectData: transformedProjectData,
        projectComparisonData: projectComparisonData,
        essRatingData,
        esrBadge: esrBadge.has(project_id)
          ? esrBadge
          : updateMap(esrBadge, project_id, !!essRatingData ? false : true),
        projectCreditingData: {
          ...projectCreditingData,
          issuanceSizeCategory: getIssuanceSizeCategory(projectCreditingData?.totalIssued),
        },
        isLoading: false,
        relatedContentArticles: relatedContentArticles as any[],
      }));
    }
  }, [
    isFetched,
    data,
    essRatingData,
    setProjectState,
    isFetchedEssRating,
    isEssLoading,
    isRefetching,
    isProjectCompLoading,
    isProjectCompRefetch,
    projectComparisonData,
    projectCreditingData,
    isProjectCreditingDataFetched,
    isProjectCreditingLoading,
    isRelatedContentArticlesLoading,
    relatedContentArticles,
    project_id,
  ]);

  useEffect(() => {
    return () => {
      resetProjectState();
    };
  }, [resetProjectState]);

  return (
    <Box sx={{ height: 1, position: 'relative', width: 1 }}>
      <Paywall
        showPaywall={!data?.hasPermission}
        isLoadingComplete={isFetched}
        headingText='Upgrade to get the why behind a rating'
        bodyText='Richer project-level data and insights are available to subscribers with a Standard or Expert subscription. To upgrade your subscription, fill out the form and our customer success team will contact you soon.'
      />
      <ProjectHeader />
      <ProjectSidebar />
      <Box
        sx={{
          pl: 12,
          pt: 5,
          pr: 5,
          pb: 20,
          height: 1,
          overflowY: 'scroll',
          position: 'fixed',
          mb: 4,
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
