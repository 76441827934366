import { useQuery } from '@tanstack/react-query';

import { fetchApi } from '../strapi';

export default function useFetchAllResearchUpdates() {
  const query = useQuery({
    queryKey: ['reseach-updates'],
    queryFn: async () => {
      const response = await fetchApi({
        endpoint: 'research-updates',
        query: {
          'sort[0]': 'date:desc',
          'populate[0]': 'author',
          'pagination[pageSize]': '500',
          status: process.env.IS_PRODUCTION_APP === 'true' ? 'published' : 'draft',
        },
        wrappedByKey: 'data',
      });
      return response;
    },
  });

  return query;
}
