import { Box, Card, Stack, Typography } from '@mui/joy';

import { Article } from '../../../types/strapi';
import ResearchUpdateCard from '../../insightsHubCategory/components/ResearchUpdateCard';

interface Props {
  isLoading: boolean;
  updates: Array<Article>;
}

export default function NextUpResearchUpdates({ isLoading, updates }: Props) {
  return (
    <Card>
      <Stack direction={'column'} gap={3} width={'100%'}>
        <Typography level='h3' fontWeight={'xl'}>
          Next Up
        </Typography>
        <Box display={'grid'} gap={3} gridTemplateColumns={{ xs: '1fr', lg: 'repeat(3,1fr)' }}>
          {isLoading
            ? Array(3)
                .fill('')
                .map((_, i) => {
                  return <ResearchUpdateCard data={{}} isLoading={isLoading} key={i} />;
                })
            : updates?.map((update) => {
                return <ResearchUpdateCard data={update} key={update?.id} />;
              })}
        </Box>
      </Stack>
    </Card>
  );
}
