import { styled } from '@mui/joy';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.vars.palette['hyperlink']['text'],
  textUnderlineOffset: '0.15em',
  '& > p': {
    color: theme.vars.palette['hyperlink']['text'],
  },
  textDecoration: 'none !important',
  '&:hover': {
    textDecoration: 'underline !important',
  },
}));
