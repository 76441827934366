import { useEffect, useState } from 'react';

const checkIfMobileScreen = () => window.innerWidth < 650;

export default function useIsMobile() {
  const [isLargeScreen, setIsLargeScreen] = useState(checkIfMobileScreen());

  useEffect(() => {
    const onResize = () => {
      setIsLargeScreen(checkIfMobileScreen());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isLargeScreen;
}
