import { useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Dropdown,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Radio,
  Stack,
  styled,
  Typography,
  useColorScheme,
} from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import CalyxLogo from 'jsx:../assets/icons/logo.svg';
import CalyxLogoDark from 'jsx:../assets/icons/logoDark.svg';
import { BiLock } from 'react-icons/bi';
import { FiPlusCircle } from 'react-icons/fi';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { IoMenu } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { FOLLOWING } from '../pages/home/constants';
import { track } from '../services/analytics';
import {
  EVENT_DASHBOARD_PAGE,
  EVNTAPP_contact_menu_click,
  EVNTAPP_myprofile_menu_click,
  EVNTAPP_programs_menu_click,
  EVNTAPP_retiree_insights_menu_click,
  EVNTAPP_search_menu_click,
  EVNTAPP_signout_menu_click,
  EVNTAPP_where_to_buy_menu_click,
  EVNTAPP_research_hub_menu_click,
} from '../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../services/axios';
import { dashboardProjects } from '../services/axios/endpoints';
import { appState } from '../stores/app';
import { userState } from '../stores/user';
import { useFetchUserPermissions } from '../utils/hooks/useFetchUserPermission';
import useIsCalyxTeamMember from '../utils/hooks/useIsCalyxTeamMember';
import useIsMobile from '../utils/hooks/useIsMobile';
import { useLogout } from '../utils/hooks/useLogout';

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  borderRadius: theme.radius.xl,
  height: '44px',
  padding: 1,
  border: `1px solid transparent`,
  '&:hover': {
    backgroundColor: theme.palette.primary.outlinedHoverBg,
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledNavBox = styled(Box)<{ active?: boolean }>(({ theme, active }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`,
  backgroundColor: active ? theme.palette.primary.outlinedHoverBg : '',
}));

const StyledAccordionSummary = styled(AccordionSummary)({
  background: 'transparent !important',
  ':active': {
    border: 'none',
  },
  '>button': {
    background: 'transparent !important',
    justifyContent: 'start',
  },
});

const FOLLOWING_PROJECTS_ON_SEARCH_PAGE = '/search?following=true';

interface NavItem {
  path: string;
  title: string;
  isNew?: boolean;
  isDropdown?: boolean;
  items?: Array<NavItem>;
  isBeta?: boolean;
  event: string;
  hasPermission?: boolean;
}

const Navbar = () => {
  const { hasPermissionForRetirementInsights, hasPermissionForWhereToBuy } =
    useFetchUserPermissions();
  const userInfo = useRecoilValue(userState);
  const [isHoveringOverInsights, setIsHoveringOverInsights] = useState<boolean>(false);
  const [isHoveringOverProfile, setIsHoveringOverProfile] = useState<boolean>(false);
  const logout = useLogout();
  const { mode, setMode } = useColorScheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const appStateData = useRecoilValue(appState);
  const setAppState = useSetRecoilState(appState);
  const isCalyxTeamMember = useIsCalyxTeamMember();
  const isMobile = useIsMobile();

  const navItemsConfig: Array<NavItem> = useMemo(
    () => [
      {
        title: 'Search',
        path: '/search',
        isBeta: false,
        event: EVNTAPP_search_menu_click,
      },
      {
        title: 'Research hub',
        path: '/research-hub',
        event: EVNTAPP_research_hub_menu_click,
      },
      {
        title: 'Where to buy',
        path: '/where_to_buy',
        isNew: false,
        event: EVNTAPP_where_to_buy_menu_click,
        hasPermission: hasPermissionForWhereToBuy,
      },
      {
        title: 'Retirement insights',
        path: '/retirement_insights',
        event: EVNTAPP_retiree_insights_menu_click,
        hasPermission: hasPermissionForRetirementInsights,
      },
      {
        title: 'Programs',
        path: '/programs',
        isNew: true,
        event: EVNTAPP_programs_menu_click,
      },
    ],
    [hasPermissionForRetirementInsights, hasPermissionForWhereToBuy]
  );
  const profileMenuConfig = useMemo(
    () => [
      {
        title: 'Settings',
        onClick: () => {
          track(EVNTAPP_myprofile_menu_click, EVENT_DASHBOARD_PAGE);
          window.open(
            process.env.IS_PRODUCTION_APP
              ? 'https://auth.calyxglobal.com/account'
              : 'https://auth.calyxstaging.com/account',
            '_blank'
          );
        },
      },
      {
        title: 'Contact Us',
        onClick: () => {
          track(EVNTAPP_contact_menu_click, EVENT_DASHBOARD_PAGE);
          navigate('/contact');
        },
      },
      {
        title: 'Log Out',
        onClick: () => {
          track(EVNTAPP_signout_menu_click, EVENT_DASHBOARD_PAGE);
          logout();
        },
      },
    ],
    [logout, navigate]
  );

  const themeOptionsList: Array<{ label: string; value: 'light' | 'dark' }> = useMemo(
    () => [
      {
        label: 'Light Mode',
        value: 'light',
      },
      {
        label: 'Dark Mode',
        value: 'dark',
      },
    ],
    []
  );

  const envOptionsList: Array<{ label: string; value: boolean }> = useMemo(
    () => [
      {
        label: 'Production',
        value: false,
      },
      {
        label: 'Staging',
        value: true,
      },
    ],
    []
  );

  const handleNavItemClick = (event: string) => {
    track(event, EVENT_DASHBOARD_PAGE);
  };

  const handleThemeSwitch = (mode: 'dark' | 'light') => {
    setMode(mode);
  };

  const { data: followingProjectsCount, isLoading } = useQuery({
    queryKey: [`dashboard-ratings-${FOLLOWING}`],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(dashboardProjects, {
        params: { type: FOLLOWING },
      }),
    select: (data) =>
      data?.data?.projects
        ?.map((project: any) => {
          return project?.project_crediting_periods?.map((period: any) => {
            return period;
          });
        })
        .flat()?.length,
  });

  const handleEnvToggle = () => {
    setAppState({ isStaging: !appStateData.isStaging });
  };

  return (
    <Stack
      sx={{
        position: 'fixed',
        width: 1,
        top: 0,
        zIndex: 11,
        background: (theme) => theme.palette.background.surface,
        borderBottom: (theme) => `1px solid ${theme.palette.primary.outlinedBorder}`,
      }}
    >
      <Drawer
        open={isDrawerOpen}
        anchor='right'
        sx={{ display: { lg: 'none' } }}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Stack direction={'row'} justifyContent={'end'} sx={{ m: 1 }}>
          <IconButton variant='plain' onClick={() => setIsDrawerOpen(false)}>
            <IoMenu fontSize={20} />
          </IconButton>
        </Stack>
        {navItemsConfig.map(
          ({
            path,
            title,
            isNew,
            isDropdown = false,
            items = [],
            isBeta,
            event,
            hasPermission = true,
          }) =>
            isDropdown ? (
              <Accordion variant='plain'>
                <AccordionGroup disableDivider sx={{ maxWidth: 400 }}>
                  <StyledAccordionSummary sx={{ px: 3, py: 1 }}>{title}</StyledAccordionSummary>
                  <AccordionDetails>
                    {items.map(({ path, title, event: childEvent }) => {
                      return (
                        <Link
                          to={path}
                          style={{ textDecoration: 'none' }}
                          onClick={() => handleNavItemClick(childEvent)}
                        >
                          <StyledNavBox active={!!location.pathname.startsWith(path)}>
                            <Typography fontSize={'md'} fontWeight={'md'}>
                              {title}
                            </Typography>
                          </StyledNavBox>
                        </Link>
                      );
                    })}
                  </AccordionDetails>
                </AccordionGroup>
              </Accordion>
            ) : (
              <Link
                to={path}
                style={{ textDecoration: 'none' }}
                onClick={() => handleNavItemClick(event)}
              >
                <StyledNavBox active={!!location.pathname.startsWith(path)}>
                  <Typography
                    startDecorator={hasPermission ? null : <BiLock />}
                    endDecorator={
                      isNew || isBeta ? (
                        <>
                          {isNew ? (
                            <Chip variant='solid' color='primary'>
                              New
                            </Chip>
                          ) : null}
                          {isBeta ? (
                            <Chip variant='solid' color='primary'>
                              Beta
                            </Chip>
                          ) : null}
                        </>
                      ) : null
                    }
                  >
                    {title}
                  </Typography>
                </StyledNavBox>
              </Link>
            )
        )}
      </Drawer>
      <Grid container justifyContent={'space-between'} alignItems={'center'} padding={'4px 24px'}>
        <Stack alignItems={'center'} direction={'row'} gap={3}>
          <Link to={'/'}>
            {mode === 'light' ? (
              <CalyxLogo width={164} height={36} />
            ) : (
              <CalyxLogoDark width={164} height={36} />
            )}
          </Link>
          <Grid
            container
            spacing={2}
            alignItems={'center'}
            sx={{ display: { xs: 'none', lg: 'flex' } }}
          >
            {navItemsConfig.map(
              ({
                path,
                title,
                isNew,
                isDropdown = false,
                items = [],
                isBeta = false,
                event,
                hasPermission = true,
              }) => {
                const isActive = isDropdown
                  ? items?.some(({ path }) => path === location.pathname)
                  : path === location.pathname || location.pathname.startsWith(path);
                return isDropdown ? (
                  <Grid
                    padding={1}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onMouseEnter={() => {
                      setIsHoveringOverInsights(true);
                    }}
                    onMouseLeave={() => {
                      setIsHoveringOverInsights(false);
                    }}
                  >
                    <Dropdown>
                      <Stack mt={'4px'}>
                        <MenuButton variant='plain' color='primary'>
                          <Typography
                            fontSize={'md'}
                            fontWeight={'md'}
                            color='primary'
                            endDecorator={
                              isHoveringOverInsights ? <IoChevronUp /> : <IoChevronDown />
                            }
                          >
                            {title}
                          </Typography>
                        </MenuButton>
                        {isActive ? (
                          <Box
                            width={'100%'}
                            position={'relative'}
                            top={10}
                            height={'4px'}
                            sx={(theme) => {
                              return { background: theme.palette.primary.solidBg };
                            }}
                          />
                        ) : (
                          <Box height={'4px'} />
                        )}
                      </Stack>
                      <Menu open={isHoveringOverInsights}>
                        {items.map(({ path, title, event: childEvent }) => {
                          return (
                            <Link
                              to={path}
                              style={{ textDecoration: 'none' }}
                              onClick={() => handleNavItemClick(childEvent)}
                            >
                              <MenuItem>
                                <Typography fontSize={'md'} fontWeight={'md'}>
                                  {title}
                                </Typography>
                              </MenuItem>
                            </Link>
                          );
                        })}
                      </Menu>
                    </Dropdown>
                  </Grid>
                ) : (
                  <StyledLink
                    to={path}
                    style={{ textDecoration: 'none' }}
                    onClick={() => handleNavItemClick(event)}
                  >
                    <Stack>
                      <Grid padding={1} sx={{ cursor: 'pointer' }} mt={'4px'}>
                        <Typography
                          fontSize={'md'}
                          fontWeight={'md'}
                          color='primary'
                          startDecorator={hasPermission ? null : <BiLock />}
                          endDecorator={
                            isNew || isBeta ? (
                              <>
                                {isNew ? (
                                  <Chip variant='solid' color='primary'>
                                    New
                                  </Chip>
                                ) : null}
                                {isBeta ? (
                                  <Chip variant='solid' color='primary'>
                                    Beta
                                  </Chip>
                                ) : null}
                              </>
                            ) : null
                          }
                        >
                          {title}
                        </Typography>
                      </Grid>
                      {isActive ? (
                        <Box
                          width={'100%'}
                          position={'relative'}
                          top={8}
                          height={'4px'}
                          sx={(theme) => {
                            return { background: theme.palette.primary.solidBg };
                          }}
                        />
                      ) : (
                        <Box height={'4px'} />
                      )}
                    </Stack>
                  </StyledLink>
                );
              }
            )}
          </Grid>
        </Stack>
        <Stack alignItems={'center'} gap={1} direction={'row'}>
          {isMobile ? null : (
            <>
              <StyledLink to={FOLLOWING_PROJECTS_ON_SEARCH_PAGE}>
                <Button
                  size='md'
                  variant='plain'
                  color='primary'
                  startDecorator={<FiPlusCircle size={20} />}
                >
                  <Typography fontSize={'16px'} fontWeight={'md'} color='primary'>
                    Following ({isLoading ? '...' : followingProjectsCount})
                  </Typography>
                </Button>
              </StyledLink>
              <div
                onMouseEnter={() => {
                  setIsHoveringOverProfile(true);
                }}
                onMouseLeave={() => {
                  setIsHoveringOverProfile(false);
                }}
                style={{ padding: '3px 0px', margin: '-3px 0px' }}
              >
                <Dropdown>
                  <MenuButton variant='plain' color='primary'>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      <Avatar
                        alt={`${userInfo?.name}`}
                        src={`${userInfo?.profile_image}`}
                        variant='outlined'
                        color='primary'
                      />
                      <Typography fontSize={'md'} fontWeight={'md'} color='primary'>
                        My Account
                      </Typography>
                      {isHoveringOverProfile ? <IoChevronUp /> : <IoChevronDown />}
                    </Stack>
                  </MenuButton>
                  <Menu
                    variant='outlined'
                    sx={{
                      minWidth: '200px',
                    }}
                    open={isHoveringOverProfile}
                  >
                    <FormControl sx={{ mb: 1 }}>
                      <FormLabel sx={{ paddingX: 1, fontWeight: 'xl', fontSize: 'sm' }}>
                        Appearance
                      </FormLabel>
                      {themeOptionsList.map(({ label, value }) => {
                        return (
                          <MenuItem onClick={() => handleThemeSwitch(value)}>
                            <Radio
                              value={value}
                              variant='outlined'
                              size='md'
                              color='primary'
                              sx={{ fontSize: 'md' }}
                              checked={mode === value}
                            />
                            <Typography fontSize={'md'} fontWeight={'sm'}>
                              {label}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </FormControl>
                    {isCalyxTeamMember ? (
                      <FormControl sx={{ mb: 1 }}>
                        <FormLabel sx={{ paddingX: 1, fontWeight: 'xl', fontSize: 'sm' }}>
                          App Environment
                        </FormLabel>
                        {envOptionsList.map(({ label, value }) => {
                          return (
                            <MenuItem onClick={handleEnvToggle}>
                              <Radio
                                value={value}
                                variant='outlined'
                                size='md'
                                color='primary'
                                sx={{ fontSize: 'md' }}
                                checked={appStateData.isStaging === value}
                              />
                              <Typography fontSize={'md'} fontWeight={'sm'}>
                                {label}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </FormControl>
                    ) : null}
                    {profileMenuConfig.map(({ onClick, title }) => {
                      return (
                        <>
                          <Divider />
                          <MenuItem onClick={onClick}>
                            <Typography fontSize={'md'} fontWeight={'sm'}>
                              {title}
                            </Typography>
                          </MenuItem>
                        </>
                      );
                    })}
                  </Menu>
                </Dropdown>
              </div>
            </>
          )}

          <IconButton
            variant='plain'
            onClick={() => setIsDrawerOpen(true)}
            sx={{ display: { lg: 'none' } }}
          >
            <IoMenu fontSize={20} />
          </IconButton>
        </Stack>
      </Grid>
    </Stack>
  );
};

export { Navbar };
