//PAGES
export const EVENT_DASHBOARD_PAGE = 'dashboard';
export const EVENT_SEARCH_PAGE = 'search';
export const EVENT_EXECUTIVE_SUMMARY_PAGE = 'executive summary';
export const EVENT_GHG_SUMMARY_PAGE = 'GHG';
export const EVENT_SDG_SUMMARY_PAGE = 'SDG';
export const EVENT_PROJECT_COMPARISON_PAGE = 'GHG';
export const EVENT_RETIREMENT_INSIGHTS_PAGE = 'retirement_insights';
export const EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE =
  'retirement_insights_company_drill_down';
export const EVENT_BLOG_PAGE = 'blog';
export const EVENT_RESOURCE_PAGE = 'resources';
export const EVENT_SAFEGUARDS_PAGE = 'safeguards';
export const EVENT_ESR_RATING_PAGE = 'esr_rating';
export const EVENT_WHERE_TO_BUY_PAGE = 'where_to_buy';
export const EVENT_RELATED_CONTENT_PAGE = 'executive_summary';
export const EVENT_INSIGHTS_HUB_PAGE = 'research-hub';
export const EVENT_PROGRAM_SCREENING_PAGE = 'program_screening';

//DASHBOARD PAGE EVENTS
export const EVNTAPP_tab_recently_added = 'EVNTAPP_tab_recently_added';
export const EVNTAPP_tab_most_retired = 'EVNTAPP_tab_most_retired';
export const EVNTAPP_tab_most_issued = 'EVNTAPP_tab_most_issued';
export const EVNTAPP_tab_following = 'EVNTAPP_tab_following';
export const EVNTAPP_blog_show_more = 'EVNTAPP_blog_show_more';
export const EVNTAPP_SDG_btn_click = 'EVNTAPP_SDG_btn_click';
export const EVNTAPP_GHG_btn_click = 'EVNTAPP_GHG_btn_click';
export const EVNTAPP_search_enter = 'EVNTAPP_search_enter';
export const EVNTAPP_search_click = 'EVNTAPP_search_click';

//SEARCH PAGE EVENTS
export const EVNTAPP_list_btn_click = 'EVNTAPP_list_btn_click';
export const EVNTAPP_chart_btn_click = 'EVNTAPP_chart_btn_click';
export const EVNTAPP_filter_btn_click = 'EVNTAPP_filter_btn_click';
export const EVNTAPP_filter_location = 'EVNTAPP_filter_location';
export const EVNTAPP_filter_type = 'EVNTAPP_filter_type';
export const EVNTAPP_filter_GHGRating = 'EVNTAPP_filter_GHGRating';
export const EVNTAPP_filter_SDGRating = 'EVNTAPP_filter_SDGRating';
export const EVNTAPP_filter_attributes = 'EVNTAPP_filter_attributes';
export const EVNTAPP_filter_vintage = 'EVNTAPP_filter_vintage';
export const EVNTAPP_filter_confirmedSDGs = 'EVNTAPP_filter_confirmedSDGs';
export const EVNTAPP_filter_SDGCertificate = 'EVNTAPP_filter_SDGCertificate';
export const EVNTAPP_search_change_pageSize = 'EVNTAPP_search_change_pageSize';
export const EVNTAPP_filter_ESR_screening = 'EVNTAPP_filter_ESR_screening';

//EXECUTIVE SUMMARY PAGE EVENTS
export const EVNTAPP_GHG_rating_click = 'EVNTAPP_GHG_rating_click';
export const EVNTAPP_SDG_rating_click = 'EVNTAPP_SDG_rating_click';
export const EVNTAPP_Follow_click = 'EVNTAPP_Follow_click';
export const EVNTAPP_UnFollow_click = 'EVNTAPP_UnFollow_click';
export const EVNTAPP_project_comparison_click = 'EVNTAPP_project_comparison_click';
export const EVNTAPP_this_type_click = 'EVNTAPP_this_type_click';
export const EVNTAPP_All_projects_click = 'EVNTAPP_All_projects_click';
export const EVNTAPP_Back_button_click = 'EVNTAPP_Back_button_click';
export const EVNTAPP_related_content_list_click = 'EVNTAPP_related_content_list_click';
export const EVNTAPP_related_content_click = 'EVNTAPP_related_content_click';
export const EVNTAPP_attribute_detail_clicked = 'EVNTAPP_attribute_detail_clicked';
export const PGV_exec_summary = 'PGV_exec_summary';
export const EVNTAPP_ESR_screening_click = 'EVNTAPP_ESR_screening_click';

//GHG SUMMARY PAGE EVENTS
export const EVNTAPP_ghg_section_clicked = 'EVNTAPP_ghg_section_clicked';
export const EVNTAPP_tab_overview = 'EVNTAPP_tab_overview';
export const EVNTAPP_tab_additionality = 'EVNTAPP_tab_additionality';
export const EVNTAPP_additionality_readmore_click = 'EVNTAPP_additionality_readmore_click';
export const EVNTAPP_tab_overcrediting = 'EVNTAPP_tab_overcrediting';
export const EVNTAPP_overcrediting_readmore_click = 'EVNTAPP_overcrediting_readmore_click';
export const EVNTAPP_tab_permanence = 'EVNTAPP_tab_permanence';
export const EVNTAPP_permanence_readmore_click = 'EVNTAPP_permanence_readmore_click';
export const EVNTAPP_tab_overlappingclaims = 'EVNTAPP_tab_overlappingclaims';
export const EVNTAPP_view_deep_dive_click = 'EVNTAPP_view_deep_dive_click';
export const PGVAPP_deep_dive_content_viewed = 'PGVAPP_deep_dive_content_viewed';
export const PGVAPP_upgrade_modal_viewed = 'PGVAPP_upgrade_modal_viewed';
export const PGVAPP_submit_upgrade_modal_viewed = 'PGVAPP_submit_upgrade_modal_viewed';
export const EVNTAPP_submit_upgrade_modal_clicked = 'EVNTAPP_submit_upgrade_modal_clicked';
export const EVNTAPP_upgrade_request_submitted = 'EVNTAPP_upgrade_request_submitted';
export const PGV_ghg_summary = 'PGV_ghg_summary';

//SDG SUMMARY PAGE EVENTS
export const EVNTAPP_sdg_readmore_click = 'EVNTAPP_sdg_readmore_click';
export const EVNT_sdg_go_to_esr_screening_clicked = 'EVNT_sdg_go_to_esr_screening_clicked';

//PROJECT COMPARISON / PROJECT ANALYTICS PAGE EVENTS
export const EVNTAPP_project_comparison_chart_click = 'EVNTAPP_project_comparison_chart_click';
export const EVNTAPP_project_comparison_this_type_click =
  'EVNTAPP_project_comparison_this_type_click';
export const EVNTAPP_project_comparison_all_type_click =
  'EVNTAPP_project_comparison_all_type_click';
export const EVNTAPP_activity_year_click = 'EVNTAPP_activity_year_click';
export const EVNTAPP_vintage_click = 'EVNTAPP_vintage_click';
export const EVNTAPP_retiree_analytics_change_pageSize =
  'EVNTAPP_retiree_analytics_change_pageSize';
export const EVNTAPP_retiree_analytics_sort_retiree = 'EVNTAPP_retiree_analytics_sort_retiree';
export const EVNTAPP_retiree_analytics_sort_2024 = 'EVNTAPP_retiree_analytics_sort_2024';
export const EVNTAPP_retiree_analytics_sort_2023 = 'EVNTAPP_retiree_analytics_sort_2023';
export const EVNTAPP_retiree_analytics_sort_2022 = 'EVNTAPP_retiree_analytics_sort_2022';
export const EVNTAPP_retiree_analytics_sort_2021 = 'EVNTAPP_retiree_analytics_sort_2021';
export const EVNTAPP_retiree_analytics_sort_2020 = 'EVNTAPP_retiree_analytics_sort_2020';
export const EVNTAPP_retiree_analytics_sort_total = 'EVNTAPP_retiree_analytics_sort_total';

//RETIREE INSIGHTS PAGE EVENTS
export const EVNTAPP_retiree_insights_change_pageSize = 'EVNTAPP_retiree_insights_change_pageSize';
export const EVNTAPP_retiree_insights_sort_retiree = 'EVNTAPP_retiree_insights_sort_retiree';
export const EVNTAPP_retiree_insights_sort_2024 = 'EVNTAPP_retiree_insights_sort_2024';
export const EVNTAPP_retiree_insights_sort_2023 = 'EVNTAPP_retiree_insights_sort_2023';
export const EVNTAPP_retiree_insights_sort_2022 = 'EVNTAPP_retiree_insights_sort_2022';
export const EVNTAPP_retiree_insights_sort_2021 = 'EVNTAPP_retiree_insights_sort_2021';
export const EVNTAPP_retiree_insights_sort_2020 = 'EVNTAPP_retiree_insights_sort_2020';
export const EVNTAPP_retiree_insights_sort_total = 'EVNTAPP_retiree_insights_sort_total';

//BLOG PAGE EVENTS
export const EVNTAPP_tag_company_news = 'EVNTAPP_tag_company_news';
export const EVNTAPP_tag_events = 'EVNTAPP_tag_events';
export const EVNTAPP_tag_insights = 'EVNTAPP_tag_insights';
export const EVNTAPP_tag_product = 'EVNTAPP_tag_product';
export const EVNTAPP_tag_research = 'EVNTAPP_tag_research';
export const EVNTAPP_blog_detail_click = 'EVNTAPP_blog_detail_click';
export const EVNTAPP_pinned_post_click = 'EVNTAPP_pinned_post_click';
export const PGV_blog_detail = 'PGV_blog_detail';

//RESOURCES PAGE EVENTS
export const EVNTAPP_tag_reports = 'EVNTAPP_tag_reports';
export const EVNTAPP_resource_click = 'EVNTAPP_resource_click';
export const PGVAPP_resource_detail_download_btn_click = 'EVNTAPP_resource_download_clicked';
export const PGV_resource_detail = 'PGV_resource_detail';

//SAFEGUARD PAGE EVENTS
export const EVNTAPP_search_btn_click = 'EVNTAPP_search_btn_click';
export const EVNTAPP_sample_report_link_click = 'EVNTAPP_sample_report_link_click';
export const EVNTAPP_esr_report_btn_click = 'EVNTAPP_esr_report_btn_click';

//ESS RATING PAGE EVENTS
export const EVNTAPP_ess_report_link = 'EVNTAPP_ess_report_link';
export const EVNTAPP_ess_sample_report_link = 'EVNTAPP_ess_sample_report_link';
//ESR EVENTS
export const PGV_esr_summary = 'PGV_esr_summary';
export const EVNT_esr_section_clicked = 'EVNT_esr_section_clicked';
export const EVNT_request_esr_screening = 'EVNT_request_esr_screening';
export const EVNT_request_esr_screening_submitted = 'EVNT_request_esr_screening_submitted';
export const EVNT_view_sample_esr_report = 'EVNT_view_sample_esr_report';
export const EVNT_esr_comparison_view_toggled = 'EVNT_esr_comparison_view_toggled';
export const EVNT_esr_see_methodology_details_clicked = 'EVNT_esr_see_methodology_details_clicked';

//WHERE TO BUY PAGE EVENTS
export const EVNTAPP_view_more_on_seller_website = 'EVNTAPP_view_more_on_seller_website';
export const EVNTAPP_register_interest = 'EVNTAPP_register_interest';
export const EVNTAPP_project_name = 'EVNTAPP_project_name';
export const EVNTAPP_portfolio_bundle = 'EVNTAPP_portfolio_bundle';
export const EVNTAPP_portfolio_bundle_dropdown = 'EVNTAPP_portfolio_bundle_dropdown';
export const EVNTAPP_bundle_project_name = 'EVNTAPP_bundle_project_name';

//RELATED CONTENT PAGE EVENTS
export const EVNTAPP_related_content_detail_click = 'EVNTAPP_related_content_detail_click';

//TOP MENU EVENTS
export const EVNTAPP_home_menu_click = 'EVNTAPP_home_menu_click';
export const EVNTAPP_search_menu_click = 'EVNTAPP_search_menu_click';
export const EVNTAPP_insights_menu_click = 'EVNTAPP_insights_menu_click';
export const EVNTAPP_blog_menu_click = 'EVNTAPP_blog_menu_click';
export const EVNTAPP_donna_notes_menu_click = 'EVNTAPP_donna_notes_menu_click';
export const EVNTAPP_resources_menu_click = 'EVNTAPP_resources_menu_click';
export const EVNTAPP_contact_menu_click = 'EVNTAPP_contact_menu_click';
export const EVNTAPP_following_menu_click = 'EVNTAPP_following_menu_click';
export const EVNTAPP_myprofile_menu_click = 'EVNTAPP_myprofile_menu_click';
export const EVNTAPP_signout_menu_click = 'EVNTAPP_signout_menu_click';
export const EVNTAPP_safeguards_menu_click = 'EVNTAPP_safeguards_menu_click';
export const EVNTAPP_where_to_buy_menu_click = 'EVNTAPP_where_to_buy_menu_click';
export const EVNTAPP_retiree_insights_menu_click = 'EVNTAPP_retiree_insights_menu_click';
export const EVNTAPP_programs_menu_click = 'EVNTAPP_programs_menu_click';
export const EVNTAPP_research_hub_menu_click = 'EVNTAPP_research_hub_menu_click';

//LEFT NAVIGATION EVENTS
export const EVNTAPP_executive_summary_menu_click = 'EVNTAPP_executive_summary_menu_click';
export const EVNTAPP_ghg_rating_menu_click = 'EVNTAPP_ghg_rating_menu_click';
export const EVNTAPP_sdg_rating_menu_click = 'EVNTAPP_sdg_rating_menu_click';
export const EVNTAPP_analytics_menu_click = 'EVNTAPP_analytics_menu_click';
export const EVNTAPP_esr_rating_menu_click = 'EVNTAPP_esr_rating_menu_click';
export const EVNTAPP_related_content_menu_click = 'EVNTAPP_related_content_menu_click';

//RETIREMENT INSIGHTS V2 EVENTS
export const PGV_retirement_insights = 'PGV_retirement_insights';
export const EVNT_retirement_insights_filter_panel_opened =
  'EVNT_retirement_insights_filter_panel_opened';
export const EVNT_retirement_table_filters_applied = 'EVNT_retirement_table_filters_applied';
export const EVNT_retirement_table_searched = 'EVNT_retirement_table_searched';
export const PGV_retirement_company_drilldown = 'PGV_retirement_company_drilldown';
export const EVNT_retirement_company_chart_breakdown_selected =
  'EVNT_retirement_company_chart_breakdown_selected';
export const EVNT_retirement_company_breakdown_toggled =
  'EVNT_retirement_company_breakdown_toggled';
export const EVNT_retirement_company_widget_filters_applied =
  'EVNT_retirement_company_widget_filters_applied';

//Insights Hub Events
export const PGV_research_hub_main = 'PGV_research_hub_main';
export const PGV_research_hub_content_detail = 'PGV_research_hub_content_detail';
export const PGV_research_hub_category_detail = 'PGV_research_hub_category_detail';
export const EVNT_research_hub_searched = 'EVNT_research_hub_searched';
export const EVNT_research_hub_resource_downloaded = 'EVNT_research_hub_resource_downloaded';
//PROGRAM SCREENING
export const PGV_program_screening = 'PGV_program_screening';
export const EVNT_program_approach_clicked = 'EVNT_program_approach_clicked';
export const EVNT_request_program_screening = 'EVNT_request_program_screening';
export const EVNT_request_program_screening_submitted = 'EVNT_request_program_screening_submitted';
