import { Box, Card, Skeleton, Stack, Tooltip, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useParams } from 'react-router-dom';

import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { retireeAggregateData, retireeMarketShareData } from '../../../services/axios/endpoints';
import { formatNumberWithCommas } from '../../../utils/functions/number';
import { useFetchUserPermissions } from '../../../utils/hooks/useFetchUserPermission';

export default function AggregateStats() {
  const { retiree } = useParams();
  const { hasPermissionForRetirementInsights } = useFetchUserPermissions();

  const { data, isLoading } = useQuery({
    queryKey: ['retiree-aggregate-data', retiree],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(retireeAggregateData, {
        params: {
          retiree_name: retiree,
        },
      }),
    select: (data) => data?.data?.data,
    enabled: hasPermissionForRetirementInsights,
  });

  const { data: VCMData, isLoading: isLoadingVCM } = useQuery({
    queryKey: ['retiree-market-share-data', retiree],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(retireeMarketShareData, {
        params: {
          retiree_name: retiree,
        },
      }),
    select: (data) => data?.data?.data,
    enabled: hasPermissionForRetirementInsights,
  });

  <Skeleton sx={{ position: 'relative' }} />;

  return (
    <Stack flexDirection={{ sm: 'column', lg: 'row' }} gap={2}>
      <Card sx={{ flex: { lg: 0.5 } }}>
        <Typography fontSize={'xl'} fontWeight={'xl'} color='primary'>
          All Time
        </Typography>
        <Stack direction={'row'} gap={3} alignItems={'center'}>
          <Card sx={{ flex: 1, padding: 2, height: 1 }} variant='soft'>
            <Typography
              textTransform={'uppercase'}
              fontSize={'xs'}
              color='primary'
              fontWeight={'xl'}
            >
              CREDITS Retired
            </Typography>
            {!isLoading ? (
              <Typography fontWeight={'md'} fontSize={'lg'} color='primary' mt={'-4px'}>
                {Number(data?.total_credits_retired) ? (
                  <>{formatNumberWithCommas(Number(data?.total_credits_retired))}</>
                ) : (
                  '0'
                )}{' '}
                <Typography color='primary' fontSize={'sm'} fontWeight={'sm'}>
                  tCO<sub>2e</sub>
                </Typography>
              </Typography>
            ) : (
              <Skeleton sx={{ width: 1, height: '36px', position: 'relative' }} />
            )}
          </Card>
          <Card sx={{ flex: 1, padding: 2, height: 1 }} variant='soft'>
            <Typography
              textTransform={'uppercase'}
              fontSize={'xs'}
              color='primary'
              fontWeight={'xl'}
            >
              Projects Retired
            </Typography>
            {!isLoading ? (
              <Typography fontWeight={'md'} fontSize={'lg'} color='primary' mt={'-4px'}>
                {Number(data?.total_projects_retired) ? (
                  <>
                    {formatNumberWithCommas(Number(data?.total_projects_retired))}{' '}
                    <Typography color='primary' fontSize={'sm'} fontWeight={'sm'}>
                      Projects
                    </Typography>
                  </>
                ) : (
                  '0'
                )}
              </Typography>
            ) : (
              <Skeleton sx={{ width: 1, height: '36px', position: 'relative' }} />
            )}
          </Card>
        </Stack>
      </Card>
      <Card sx={{ flex: { lg: 1 } }}>
        <Typography fontSize={'xl'} fontWeight={'xl'} color='primary'>
          {new Date().getFullYear() - 1}
        </Typography>
        <Stack direction={'row'} gap={3} alignItems={'center'}>
          <Card sx={{ flex: 1, padding: 2, height: 1 }} variant='soft'>
            <Typography
              textTransform={'uppercase'}
              fontSize={'xs'}
              color='primary'
              fontWeight={'xl'}
            >
              CREDITS Retired
            </Typography>
            {!isLoadingVCM ? (
              <Typography fontWeight={'md'} fontSize={'lg'} color='primary' mt={'-4px'}>
                {Number(VCMData?.credit_retired) ? (
                  <>{formatNumberWithCommas(Number(VCMData?.credit_retired))}</>
                ) : (
                  '0'
                )}{' '}
                <Typography color='primary' fontSize={'sm'} fontWeight={'sm'}>
                  tCO<sub>2e</sub>
                </Typography>
              </Typography>
            ) : (
              <Skeleton sx={{ width: 1, height: '36px', position: 'relative' }} />
            )}
          </Card>
          <Card sx={{ flex: 1, padding: 2, height: 1 }} variant='soft'>
            <Typography
              textTransform={'uppercase'}
              fontSize={'xs'}
              color='primary'
              fontWeight={'xl'}
            >
              Projects Retired
            </Typography>
            {!isLoadingVCM ? (
              <Typography fontWeight={'md'} fontSize={'lg'} color='primary' mt={'-4px'}>
                {Number(VCMData?.project_retired) ? (
                  <>
                    {formatNumberWithCommas(Number(VCMData?.project_retired))}{' '}
                    <Typography color='primary' fontSize={'sm'} fontWeight={'sm'}>
                      Projects
                    </Typography>
                  </>
                ) : (
                  '0'
                )}
              </Typography>
            ) : (
              <Skeleton sx={{ width: 1, height: '36px', position: 'relative' }} />
            )}
          </Card>
          <Card sx={{ flex: 1, padding: 2, height: 1 }} variant='soft'>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography
                textTransform={'uppercase'}
                fontSize={'xs'}
                color='primary'
                fontWeight={'xl'}
              >
                Market Share
              </Typography>
              <Tooltip
                variant='solid'
                placement='left'
                arrow
                sx={{ maxWidth: '250px' }}
                title='Based on credits issued by the ACR, Climate Action Reserve, Gold Standard, and Verra.'
              >
                <Box>
                  <IoIosInformationCircleOutline />
                </Box>
              </Tooltip>
            </Stack>
            {!isLoadingVCM ? (
              <Typography fontWeight={'md'} fontSize={'lg'} color='primary' mt={'-4px'}>
                {Number(VCMData?.retiree_market_share_percentage) ? (
                  <>
                    {Number(VCMData?.retiree_market_share_percentage).toFixed(2)}
                    {'% '}
                    <Typography color='primary' fontSize={'sm'} fontWeight={'sm'}>
                      of VCM credits
                    </Typography>
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            ) : (
              <Skeleton sx={{ width: 1, height: '36px', position: 'relative' }} />
            )}
          </Card>
          <Card sx={{ flex: 1, padding: 2, height: 1 }} variant='soft'>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography
                textTransform={'uppercase'}
                fontSize={'xs'}
                color='primary'
                fontWeight={'xl'}
              >
                Rank
              </Typography>
              <Tooltip
                variant='solid'
                placement='left'
                arrow
                sx={{ maxWidth: '250px' }}
                title='Based on credits issued by the ACR, Climate Action Reserve, Gold Standard, and Verra.'
              >
                <Box>
                  <IoIosInformationCircleOutline />
                </Box>
              </Tooltip>
            </Stack>
            {!isLoadingVCM ? (
              <Typography fontWeight={'md'} fontSize={'lg'} color='primary' mt={'-4px'}>
                {Number(VCMData?.retiree_rank) ? (
                  <>
                    {formatNumberWithCommas(Number(VCMData?.retiree_rank))}{' '}
                    <Typography color='primary' fontSize={'sm'} fontWeight={'sm'}>
                      out of
                    </Typography>{' '}
                    {formatNumberWithCommas(Number(VCMData?.total_ranked_retirees_count))}
                    <Typography color='primary' fontSize={'sm'} fontWeight={'sm'}>
                      {' '}
                      companies
                    </Typography>
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            ) : (
              <Skeleton sx={{ width: 1, height: '36px', position: 'relative', display: 'block' }} />
            )}
          </Card>
        </Stack>
      </Card>
    </Stack>
  );
}
