import { useEffect, useRef } from 'react';

import { Box, Card, Chip, Grid, Stack, Typography } from '@mui/joy';

import Image from '../../components/Image';
import { getAssetURL } from '../../services/axios/endpoints';
import { theme } from '../../theme';
import { dateFormat } from '../../utils/functions/date';
import { DistributionGraph } from '../home/components/DistributionGraph';
import { pinnedPostObj } from '../insightsHub/constants';

export const InsightsHubPinnedPost = () => {
  const topRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
  }, []);
  return (
    <Stack
      direction='column'
      spacing={3}
      width={'100%'}
      maxWidth={'1117px'}
      margin={'auto'}
      ref={topRef}
      sx={{ scrollMarginTop: '200px' }}
    >
      <Card>
        <Stack direction={'column'} spacing={3}>
          <Stack direction={'column'} spacing={1}>
            <Typography fontSize={'xl3'} fontWeight={500} color='primary' lineHeight={'133%'}>
              {pinnedPostObj.title}
            </Typography>
            <Typography fontSize={'md'} fontWeight={500} color='primary' lineHeight={'142%'}>
              {dateFormat(new Date().toDateString()).monthddyyyy}
            </Typography>
            <Chip variant='outlined' color='primary' size='lg'>
              {pinnedPostObj.post_category.display_name}
            </Chip>
          </Stack>
          <Image
            src={pinnedPostObj.cover.url}
            style={{ width: '100%', borderRadius: theme.radius.md }}
          />
          <Grid xs={12} justifyContent={'flex-start'} pt={1}>
            <Stack spacing={3}>
              <Typography>
                Calyx Global has to date assessed and rated 600+ carbon projects across more than 20
                different project types. Projects rated include removals and avoided emissions
                across nature-based to technology-based project types, from 8 different crediting
                standards and over 50 methodologies. When accounting for ratings of different carbon
                credit vintages, we have over 650 GHG ratings.
              </Typography>
              <Typography>
                We find variability of quality no matter what parameter is selected (type, standard,
                country, CORSIA eligibility and more). Sometimes the variability goes all the way
                from high risk (i.e. poor quality) to low risk, sometimes it is less broad, but a
                range (distribution) is nearly always there. While quality credits can be found in
                today’s market, there are few credit types that consistently deliver high quality.
              </Typography>
              <Typography>
                The same remark is valid for the contribution to SDGs. Among the 350+ certified
                projects we've rated (e.g., CCB, SD VISta, Gold Standard for the Global Goals), we
                find differences in the degree of impact and level of evidence provided. This leads
                to Calyx SDG Ratings from +1 to +5, with +5 being the best rating that can be
                obtained in our framework.
              </Typography>
              <Typography>
                Below are two charts that show a distribution of Calyx Ratings. These charts will be
                updated as our coverage grows.
              </Typography>
              <Card>
                <Typography level='h3'>600+ projects rated in total.</Typography>
              </Card>
            </Stack>
            <Stack mt={5} gap={5}>
              <Stack>
                <Typography fontWeight={'xl'} fontSize={'lg'}>
                  GHG Risk Rating Distribution
                </Typography>
                <DistributionGraph type={'ghg'} />
              </Stack>
              <Stack>
                <Typography fontWeight={'xl'} fontSize={'lg'}>
                  SDG Impact Rating Distribution
                </Typography>
                <DistributionGraph type={'sdg'} />
              </Stack>
            </Stack>
          </Grid>
          <Card variant='soft'>
            <Stack direction={'row'} spacing={3}>
              <Box width={'50px'}>
                <Image
                  src={getAssetURL('calyx.png')}
                  style={{ width: '50px', aspectRatio: '1/1', borderRadius: '100%' }}
                />
              </Box>
              <Stack direction={'column'}>
                <Typography
                  sx={(theme) => {
                    return { color: theme.palette.text.tertiary };
                  }}
                  level='title-sm'
                >
                  About the author
                </Typography>
                <Typography level='h4'>{pinnedPostObj?.author?.name}</Typography>
                <Typography
                  sx={(theme) => {
                    return { color: theme.palette.text.secondary };
                  }}
                  level='body-sm'
                >
                  {pinnedPostObj?.author?.title}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Card>
    </Stack>
  );
};
